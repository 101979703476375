import type { Brands } from "./getCardBrand";
import type {
  BillingMessageTemplate,
  TransactionStatus,
  MessageSource,
  MessageLogType,
  PaymentSource,
  ClaimSubStatus,
  StatementStatus,
  StatementSource,
  StatementTemplate,
} from ".prisma/client";
import { PracticeManagement } from ".prisma/client";

export const formatNumber = (number: number | undefined | null, type?: "balance" | "number") => {
  console.log(number);

  if (number) {
    if (type === "number") {
      return new Intl.NumberFormat("en-US").format(number);
    } else {
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number);
    }
  } else {
    return type == "number" ? 0 : "0.00";
  }
};

export const formatPMS = (pms: PracticeManagement) => {
  switch (pms) {
    case PracticeManagement.ACE:
      return "ACE";
    case PracticeManagement.CARESTACK:
      return "Carestack";
    case PracticeManagement.CLOUD9:
      return "Cloud9";
    case PracticeManagement.CURVE:
      return "Curve";
    case PracticeManagement.DENTICON:
      return "Denticon";
    case PracticeManagement.DENTIMAX:
      return "Dentimax";
    case PracticeManagement.DENTRIX:
      return "Dentrix";
    case PracticeManagement.DENTRIX_NI:
      return "Dentrix NI";
    case PracticeManagement.DENTRIX_ASCEND:
      return "Dentrix Ascend";
    case PracticeManagement.DENTRIX_ASCEND_NI:
      return "Dentrix Ascend NI";
    case PracticeManagement.DOLPHIN:
      return "Dolphin";
    case PracticeManagement.DSN:
    case PracticeManagement.DNS:
      return "DSN";
    case PracticeManagement.EAGLESOFT:
      return "Eaglesoft";
    case PracticeManagement.EAGLESOFT_NI:
      return "Eaglesoft NI";
    case PracticeManagement.FUSE:
      return "Fuse";
    case PracticeManagement.IDENTALSOFT:
      return "iDentalsoft";
    case PracticeManagement.OMS_VISION:
      return "OMS Vision";
    case PracticeManagement.OPENDENTAL:
      return "OpenDental";
    case PracticeManagement.OPENDENTAL_NI:
      return "OpenDental NI";
    case PracticeManagement.OPENDENTAL_CLOUD:
      return "OpenDental Cloud";
    case PracticeManagement.ORTHO_TRACK:
      return "OrthoTrack";
    case PracticeManagement.ORYX:
      return "Oryx";
    case PracticeManagement.PBS_ENDO:
      return "PBS Endo";
    case PracticeManagement.PRACTICEWORKS:
      return "PracticeWorks";
    case PracticeManagement.PRIME:
      return "PRIME";
    case PracticeManagement.QSI:
      return "QSI";
    case PracticeManagement.SENSI_CLOUD:
      return "Sensi Cloud";
    case PracticeManagement.SOFTDENT:
      return "Softdent";
    case PracticeManagement.TAB32:
      return "Tab32";
    case PracticeManagement.WINOMS:
      return "WinOMS";
    case PracticeManagement.WINOMS_SPECIAL:
      return "WinOMS Special";
    default:
      return "Other";
  }
};

export const formatTemplate = (template: BillingMessageTemplate) => {
  switch (template) {
    case "END_TEMPLATE":
      return "End Template";
    case "START_TEMPLATE":
      return "Start Template";
    case "FINAL_REMINDER_TEMPLATE":
      return "Final Reminder Template";
    case "REMINDER_TEMPLATE":
      return "Reminder Template";
    default:
      return "";
  }
};

export const formatPaymentSource = (source: PaymentSource | null) => {
  switch (source) {
    case "AUTOMATEDAR":
      return "Auto Collection";
    case "MEMBERSHIP":
      return "Subscription";
    case "ONLINE":
      return "Online";
    case "PAYMENTPLAN":
      return "Payment Plan";
    case "TERMINAL":
      return "Terminal";
    case "VIRTUAL":
      return "Virtual";
    case "INSURANCE":
      return "Insurance";
    default:
      return "";
  }
};

export const formatTransactionStatus = (status: TransactionStatus | null) => {
  switch (status) {
    case "FAILED":
      return "Failed";
    case "REFUND":
      return "Refund";
    case "SUCCESSFUL":
      return "Successful";
    default:
      return "";
  }
};

export const formatMessageSource = (source: MessageSource | null) => {
  switch (source) {
    case "AUTOMATEDAR":
      return "Auto Collection";
    case "CARD_REQUEST":
      return "Card Request";
    case "CONFIRMATION":
      return "Confirmation";
    case "MEMBERSHIP":
      return "Subscription";
    case "PAYMENTPLAN":
      return "Payment Plan";
    case "PAYMENT_REQUEST":
      return "Payment Request";
    case "CONVERSATION":
      return "Conversation";
    default:
      return "N/A";
  }
};

export const formatMessageLogType = (source: MessageLogType | null) => {
  switch (source) {
    case "EMAIL":
      return "Email";
    case "SMS":
      return "SMS";
    default:
      return "N/A";
  }
};

export const formatCardBrand = (brand: Brands | null) => {
  switch (brand) {
    case "AMERICAN_EXPRESS":
      return "American Express";
    case "MASTERCARD":
      return "Mastercard";
    case "DINERS_CLUB_INTERNATIONAL":
      return "Diners Club International";
    case "DANKORT":
      return "Dankort";
    case "RUPAY":
      return "Rupay";
    case "VISA":
      return "Visa";
    case "DISCOVER":
      return "Discover";
    case "INTERPAYMENT":
      return "Interpayment";
    default:
      return "N/A";
  }
};

export const formatClaimSubStatus = (status: ClaimSubStatus | null | undefined) => {
  switch (status) {
    case "CLAIM_NOF_ISR_INCORRECT":
      return "Claim not on file (insurance demographics missing/incorrect)";
    case "CLAIM_NOF_PAT_INCORRECT":
      return "Claim not on file (patient demographics missing/incorrect)";
    case "CLEARINGHOUSE_ERROR":
      return "Clearinghouse error";
    case "CODING_ISSUE":
      return "Coding issue";
    case "CREDENTIALING_ISSUE":
      return "Credentialing issue";
    case "ISR_TERMINATED":
      return "Insurance terminated";
    case "MISSING_DOC":
      return "Missing documentation";
    default:
      return "N/A";
  }
};

export const formatStatementStatus = (status: StatementStatus | null | undefined) => {
  switch (status) {
    case "GENERATED":
      return "Generated";
    case "MAILED":
      return "Mailed";
    default:
      return "N/A";
  }
};

export const formatStatementSource = (source: StatementSource | null | undefined) => {
  switch (source) {
    case "MANUAL":
      return "Manual";
    default:
      return "N/A";
  }
};

export const formatStatementTemplate = (template: StatementTemplate | null | undefined) => {
  switch (template) {
    case "DEFAULT":
      return "Default";
    default:
      return "N/A";
  }
};
