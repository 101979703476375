import { useRouter } from "next/router";
import React, { useState } from "react";

import { trpc } from "@calcom/trpc/react";
import { Dialog, DialogContent, Label, Select } from "@calcom/ui";

import { PaymentOptions } from "@lib/constant";

import { XmarkIcon } from "@components/Icons";
import InsurancePayment from "@components/patients/insurance";
import PaymentRequest from "@components/patients/paymentRequest";
import TerminalPayment from "@components/patients/terminalPayment";
import UnreferencedRefund from "@components/patients/unreferenced-refund";
import VirtualPayment from "@components/patients/virtualPayment";

type CreatePaymentDlgProps = {
  open: boolean;
  handler: React.Dispatch<React.SetStateAction<boolean>>;
  patientId?: number;
  officeId: number;
  appId: string;
  env: string;
  defaultTab?: TabTitle;
  createConversationRoom?: boolean;
};

type TabTitle =
  | "virtual"
  | "terminal"
  | "insurance"
  | "request"
  | "link"
  | "paymentplan"
  | "subscription"
  | "unreferenced-refund";

export default function CreatePaymentDialog(props: CreatePaymentDlgProps) {
  const router = useRouter();
  const { open, handler, patientId, officeId, appId, env, defaultTab, createConversationRoom } = props;

  const { data: office } = trpc.viewer.office.getById.useQuery({
    officeId: officeId,
  });

  const [activeTab, setActiveTab] = useState<TabTitle>(defaultTab || "virtual");

  return (
    <Dialog open={open} onOpenChange={handler}>
      <DialogContent className="min-w-screen min-h-screen rounded-none p-0 py-2" enableOverflow>
        <div className="relative min-h-screen">
          <div className="flex h-[44px] w-full items-center border-b px-8 pb-2">
            <span className="text-xl font-medium">Create Payment</span>
            <div className="flex-grow" />
            <div className="flex items-center gap-4">
              <XmarkIcon className="cursor-pointer" onClick={() => handler(false)} />
            </div>
          </div>
          <div className="relative mx-auto max-w-2xl pt-4">
            <div className="space-y-6">
              <div className="p-4 pt-0 md:p-8 md:pt-0">
                <div>
                  <Label>Payment Type</Label>
                  <Select
                    options={PaymentOptions}
                    value={PaymentOptions.find((p) => p.value === activeTab)}
                    onChange={(option) => {
                      if (option) {
                        setActiveTab(option.value as TabTitle);
                      }
                    }}
                  />
                </div>
                <div>
                  {activeTab === "virtual" ? (
                    <VirtualPayment
                      patientId={patientId}
                      officeId={officeId}
                      appId={appId}
                      env={env}
                      setPaymentDialog={handler}
                    />
                  ) : activeTab === "terminal" ? (
                    <TerminalPayment patientId={patientId} officeId={officeId} setPaymentDialog={handler} />
                  ) : activeTab === "insurance" ? (
                    <InsurancePayment
                      patientId={patientId}
                      officeId={officeId}
                      appId={appId}
                      env={env}
                      setPaymentDialog={handler}
                    />
                  ) : activeTab === "request" ? (
                    <PaymentRequest
                      patientId={patientId}
                      officeId={officeId}
                      officename={office?.officename}
                      setPaymentDialog={handler}
                      createConversationRoom={createConversationRoom}
                    />
                  ) : activeTab === "unreferenced-refund" ? (
                    <UnreferencedRefund
                      patientId={patientId}
                      officeId={officeId}
                      setPaymentDialog={handler}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
