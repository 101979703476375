import { useRouter } from "next/router";

export default function useGroupOrSingleView() {
  const router = useRouter();
  const groupViewLinks: string[] = [
    "/home",
    "/transactions",
    "/outstanding-group",
    "/group-setting",
    "/claims",
    "/claims-dcs",
    "/claims-smile",
    "/claims-beacon",
  ];

  const isGroupView = groupViewLinks.includes(router.asPath);

  return isGroupView;
}
